.staff {
  padding-top: 0;
	display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-auto-rows: minmax(120px, auto);
	gap: 8px;
	&_select-item {
		margin: 8px 0;
	}
	&_staff-item {
		min-height: 100px;
		min-width: 250px;
		&_name {
			max-width: 250px;
		}
	}
	&_add-staff {
		&_sheet .g-sheet__sheet-content > div {
			padding: var(--g-spacing-2);
      padding-top: 0;
		}
    &_hint {
      padding: 0 2rem;
      text-align: center;
      color: var(--g-color-text-hint);
    }
	}
}

.g-sheet {
	z-index: 1 !important; // FIXME find better way
}

.role-label {
	user-select: none;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
}
