.add-images-button {
	width: 100%;
	height: 100%;
	aspect-ratio: 1/1;
	display: flex;
	align-items: center;
	justify-content: center;
  &.g-button {
    height: 100%
  }
	&_plus-icon {
		height: 2rem;
		width: 2rem;
		color: var(--g-color-text-secondary);
	}
	&_input {
		display: none;
	}
}
