* {
	-webkit-tap-highlight-color: transparent;
}

.role-label .yc-button {
	height: 100%;
}

.popup {
	display: flex;
	flex-direction: column;
	padding: 8px;
	z-index: 10;

	&_user {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: var(--g-spacing-2);
		gap: var(--g-spacing-2);

		&_profile {
			cursor: pointer;
			user-select: none;
			width: 100%;
		}
	}

	&_zifer-list {
		display: flex;
		flex-direction: column;
		padding-top: var(--g-spacing-2);
		margin-top: var(--g-spacing-2);
		border-top: 1.5px solid var(--g-color-line-generic);
	}
}
