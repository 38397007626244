.events-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
	gap: var(--g-spacing-2);
	width: 100%;
}

.event-status-container {
	width: fit-content;
	padding: var(--g-spacing-2);
	padding-top: 0;
}

.event-status-container:nth-child(2) {
  width: fit-content;
	padding: var(--g-spacing-2);
  padding-bottom: 0;
}

.event-year-container {
	position: sticky;
	width: 112px;
	color: var(--g-color-text-hint);
	top: var(--g-spacing-4);
	left: var(--g-spacing-4);
	margin-left: var(--g-spacing-2);
	background-color: var(--g-color-base-background);
}

.events-month-container {
	text-transform: capitalize;
	position: sticky;
	display: flex;
	align-items: flex-end;
	top: 0;
	left: var(--g-spacing-4);
	width: 100%;
	z-index: -1;
	height: calc(var(--g-spacing-4) * 2);
	padding-left: var(--g-spacing-2);
	padding-bottom: 2px;
	background-color: var(--g-color-base-background);
	box-shadow: 0px 1px 5px var(--g-color-base-background);
}

.events-date-container {
	display: flex;
	flex-direction: column;
	position: sticky;
	align-items: baseline;
	top: 40px;
	width: 112px;
	z-index: -2;
	margin: var(--g-spacing-1) var(--g-spacing-2);
	color: var(--g-color-text-hint);
}
