$heights: (
	'xs': 24px,
	's': 32px,
	'm': 40px,
	'l': 48px,
	'xl': 56px,
);

$border-radius: (
	'xs': 4px,
	's': 4px,
	'm': 8px,
	'l': 8px,
	'xl': 8px,
);

$label-heights: (
	'xs': 24px,
	's': 32px,
	'm': 40px,
	'l': 48px,
	'xl': 56px,
);

html,
body,
#__next {
	height: 100%;
}

* {
	box-sizing: border-box;
}

body.g-root {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@each $variant, $size in $heights {
		--g-text-body-#{$variant}-line-height: #{$size};
	}

	@each $name, $radius in $border-radius {
		--g-border-radius-#{$name}: #{$radius};
	}

	--g-modal-border-radius: var(--g-border-radius-xl);

	@each $name, $height in $heights {
		--g-base-height-#{$name}: #{$height};
	}

	.g {
		&-modal__content {
			margin-bottom: 24rem;
		}

		&-user__info {
			line-height: 18px;
		}

		&-card_size_m {
			--g-card-border-radius: var(--g-border-radius-m);
		}

		&-text-input {
			&_size {
				@each $name, $height in $heights {
					&_#{$name} {
						--g-text-input-height: calc(var(--g-base-height-#{$name}) - 2px);
					}
				}
			}

			&__control {
				height: var(--g-text-input-height);
				padding: 12px;
			}

			&__additional-content {
				height: var(--g-text-input-height);

				&_placement_end {
					padding-inline-end: 0;
				}

				&_placement_start {
					padding-inline-start: 0;
				}
			}
		}

		&-label {
			&__addon {
				height: 100%;
				max-width: 32px; // to be adjusted
			}
		}

		&-button {
			&__text {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			&_size {
				@each $name, $height in $heights {
					&_#{$name} {
						--g-button-height: var(--g-base-height-#{$name});
					}
				}
			}
		}

		&-sheet__sheet-content {
			padding: 0;
		}

		&-label_size {
			@each $name, $height in $label-heights {
				&_#{$name} {
					--g-label-height: #{$height};
				}

				&_xs,
				&_s,
				&_m {
					height: var(--g-label-height);
				}
			}
		}

		&-user-avatar_size {
			@each $name, $size in $heights {
				&_#{$name} {
					--g-label-height: #{$size};
					--g-label-width: #{$size};
				}

				&_#{$name} {
					height: var(--g-label-height);
					width: var(--g-label-width);
					& > .g-user-avatar__figure {
						height: var(--g-label-height);
						width: var(--g-label-width);
					}
				}
			}
		}
	}

	.g {
		&-select-control_size {
			@each $name, $height in $heights {
				&_#{$name} {
					--_--select-options-text-right-padding: 12px;
					height: var(--g-base-height-#{$name});
				}
			}
		}

		&-text-area_size_m .g-text-area__control {
			padding: 8px 12px;
		}

		&-date-date-picker {
			width: 100%;
		}
	}

	.gc-form-row {
		--gc-form-row-field-height: 40px;
	}

	.gn-drawer__item,
	.gn-drawer__veil {
		position: fixed;
		top: 0;
		height: 100vh;
	}

	.gn-drawer__item {
		z-index: 100;
	}

	.gn-drawer__veil {
		z-index: 99;
	}
}
