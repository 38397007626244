.controls {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--g-spacing-2);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  &.g-card_type_action {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &_button {
    display: flex;
    align-items: center;
  }
}