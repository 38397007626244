.loading-elements,
.events-loading-elements,
.grid-loading-elements,
.dates-loading-elements {
	display: grid;
	gap: 8px;
	z-index: -1;
	position: sticky;
	height: calc(100dvh - 4.5rem);
	overflow: hidden;
}

.loading-elements {
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	gap: 8px;
}

.grid-loading-elements {
	padding-top: 0;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.events-loading-elements {
	padding: var(--g-spacing-2);
	padding-top: 0;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.loading-elements::after,
.grid-loading-elements::after,
.events-loading-elements::after,
.dates-loading-elements::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		to bottom,
		transparent 0%,
		var(--g-color-base-background) 80%
	);
	pointer-events: none;
}
