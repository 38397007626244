.tickets-label {
	width: 100%;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
  border-top-left-radius: var(--g-card-border-radius);
  border-top-right-radius: var(--g-card-border-radius);
	padding: var(--g-spacing-4) var(--g-spacing-2);
  // padding-right: var(--g-spacing-1);
  padding-right: 0;
	.g-label__text > div {
		width: 100%;
	}
}

.transaction-list {
	-webkit-tap-highlight-color: transparent;
}
