.content {
  padding: 8px;
  padding-bottom: 8rem;
}

.mobile-menu {
  &_container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &::after {
			content: "";
			display: block;
			position: absolute;
			z-index: -1;
			/* Set z-index lower than parent element */
			bottom: 0;
			left: 0;
			right: 0;
			height: 64px;
			background: linear-gradient(to top, var(--g-color-base-background) 90%, transparent 100%);
		}
  }
}