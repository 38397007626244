.main-desktop {
	--spacing: var(--g-spacing-4);
	--gap: var(--g-spacing-2);
	display: flex;
	&_content {
		margin-top: var(--spacing);
		margin-bottom: var(--spacing);
		margin-right: var(--spacing);
		width: 100%;
	}
}
