.iteratee {
	&_button-wrapper {
		display: flex;
		align-items: center;
		position: relative;
	}
	&_button {
		display: flex;
		position: absolute;
		left: 0;
		align-items: center;
		gap: var(--g-spacing-3);
		padding-left: var(--g-spacing-2);
		&_logo {
			width: 32px;
			height: 32px;
		}
	}
}
