.tariff {
  &_status {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .g-label__text {
      margin: 0 1rem;
      align-items: center;
    }
  }
}