body.g-root {
	.add-button {
    padding: 0 12px;
    height: 50px;
    align-items: center;
		.g-button__text {
			flex-grow: 1;
			display: flex;
			justify-content: space-between;
		}
	}

	.sum-input {
		.g-text-input__control {
			padding: 1rem 0;
		}
	}
}