.mobile-header {
	z-index: 0;
	&_text-input {
		&.menu {
			.g-text-input__control_type_input {
				text-align: center;
			}
		}

		&.search {
			.g-text-input__control_type_input {
				padding-left: 0;
				text-align: left;
			}
		}

		.g-text-input {
			&__content {
				background-color: var(--g-color-base-background);
			}
		}
	}
}

.mobile-navigation {
	padding: 0 var(--g-spacing-2);

	&-avatar .g-button__text {
		padding: 0;
		padding-right: var(--g-spacing-2);
	}

	&_nav-items {
		display: flex;
		flex-wrap: wrap;
		gap: var(--g-spacing-2);
		&_button {
			&.g-button {
				flex-grow: 1;
				min-height: 4rem;
				flex-basis: 4rem;
			}
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			word-break: break-all;
			text-overflow: ellipsis;
			gap: var(--g-spacing-1);
			padding-top: var(--g-spacing-2);
		}
	}

	&_nav-items > *:nth-child(1),
	&_nav-items > *:nth-child(2) {
		flex: 1 1 45%;
	}

	&_userpic {
		display: flex;
		justify-content: center;
		margin: var(--g-spacing-2);
	}

	&_modal {
		margin-bottom: 24rem;
	}

	&_content {
		margin-top: var(--spacing);
		margin-bottom: var(--spacing);
	}
}
