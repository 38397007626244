.profile-info {
	&_desktop {
		width: 500px;
	}
	&_mobile {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100dvh;
		padding: var(--g-spacing-4);
	}
}
